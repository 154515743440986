<template>
    <login-template>
        <notification :message="message"></notification>
        <div>
            <h2 class="mt-8 text-2xl font-bold leading-9 tracking-tight text-v3-gray-800 dark:text-v3-gray-200">Reset Password</h2>
            <p class="mt-2 text-sm leading-6 text-v3-gray-600 dark:text-v3-gray-400">
                Set a new password.
            </p>
        </div>

        <div class="mt-10">
            <div>
                <p class="text-red-600" v-if="error" v-text="error"></p>
                <form action="#" method="POST" class="space-y-6" @submit.prevent="onSubmit">
                    <div>
                        <label for="password" class="block text-sm font-medium leading-6 text-v3-gray-800 dark:text-v3-gray-200">Password</label>
                        <div class="mt-2 relative rounded-md shadow-sm">
                            <input id="password" placeholder="Password" name="password" type="password" autocomplete="new-password" class="block w-full rounded-md border-0 py-1.5 shadow-sm ring-inset ring-gray-300 placeholder:text-v3-gray-200 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 bg-v3-gray-550 dark:placeholder-v3-gray-700 dark:bg-secondary-appgray dark:text-v3-gray-200"
                                   :type="!showPassword ? 'password' : 'text'"
                                    v-model="password"
                                    v-validate="'required'"
                                   :class="{'is-invalid': errors.first('password')}">
                            <div class="absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer" @click="togglePassword">
                                <svg class="h-4 w-4 text-gray-400 dark:text-v3-gray-500" fill="currentColor" viewBox="0 0 20 20">
                                    <svg v-if="!showPassword" class="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"></path><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"></path></svg>
                                    <svg v-if="showPassword" class="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a9.97 9.97 0 011.563-3.029m5.858.908a3 3 0 114.243 4.243M9.878 9.878l4.242 4.242M9.88 9.88l-3.29-3.29m7.532 7.532l3.29 3.29M3 3l3.59 3.59m0 0A9.953 9.953 0 0112 5c4.478 0 8.268 2.943 9.543 7a10.025 10.025 0 01-4.132 5.411m0 0L21 21"></path></svg>
                                </svg>
                            </div>
                        </div>
                        <span class="mt-2 text-sm text-red-600">{{ errors.first('password') }}</span>

                    </div>
                    <div>
                        <label for="password" class="block text-sm font-medium leading-6 text-v3-gray-800 dark:text-v3-gray-200">Confirm Password</label>
                        <div class="mt-2 relative rounded-md shadow-sm">
                            <input id="confirm-password" placeholder="Confirm Password" name="confirm-password" type="password" autocomplete="new-password" class="block w-full rounded-md border-0 py-1.5 shadow-sm ring-inset ring-gray-300 placeholder:text-v3-gray-200 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 bg-v3-gray-550 dark:placeholder-v3-gray-700 dark:bg-secondary-appgray dark:text-v3-gray-200"
                                   :type="!showConfirmPassword ? 'password' : 'text'"
                                   v-model="confirmPassword"
                                   v-validate="'required'"
                                   :class="{'is-invalid': errors.first('confirm_password')}">
                            <div class="absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer" @click="toggleConfirmPassword">
                                <svg class="h-4 w-4 text-gray-400 dark:text-v3-gray-500" fill="currentColor" viewBox="0 0 20 20">
                                    <svg v-if="!showConfirmPassword" class="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"></path><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"></path></svg>
                                    <svg v-if="showConfirmPassword" class="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a9.97 9.97 0 011.563-3.029m5.858.908a3 3 0 114.243 4.243M9.878 9.878l4.242 4.242M9.88 9.88l-3.29-3.29m7.532 7.532l3.29 3.29M3 3l3.59 3.59m0 0A9.953 9.953 0 0112 5c4.478 0 8.268 2.943 9.543 7a10.025 10.025 0 01-4.132 5.411m0 0L21 21"></path></svg>
                                </svg>
                            </div>
                        </div>
                        <span class="mt-2 text-sm text-red-600">{{ errors.first('confirm_password') }}</span>
                    </div>
                    <div>
                        <button type="submit" class="flex w-full justify-center rounded-md bg-v3-gray-100 px-3 py-1.5 text-sm font-semibold leading-6 text-v3-gray-600 shadow-sm hover:bg-v3-gray-300 focus:outline-none items-center dark:bg-v3-gray-800 dark:text-white dark:hover:bg-v3-gray-600">
                            Reset Password
                            <loading v-if="loading" class="inline-flex -m-6 relative h-auto -ml-4 align-middle" color-class="text-v3-gray-600 dark:text-white"></loading>
                        </button>
                    </div>
                    <p class="mt-10 text-center text-sm text-v3-gray-600 dark:text-v3-gray-400">
                        Nevermind?
                        <a href="#" @click.prevent="goToLogin();" class="cursor-pointer font-semibold leading-6 hover:text-v3-gray-50 hover:opacity-50">Sign In</a>
                    </p>
                </form>
            </div>
        </div>
    </login-template>
</template>

<script>
import axios from "axios";
import Loading from "@/components/Loading.vue";
import LoginTemplate from "@/v3/templates/LoginTemplate.vue";
import {mapGetters} from "vuex";
import Notification from "@/components/Notification.vue";
import Siren from "super-siren";
export default {
    components: {Loading, Notification, LoginTemplate},
    data() {
        return {
            error: null,
            email: null,
            password: null,
            showPassword: false,
            loading: false,
            confirmPassword: null,
            showConfirmPassword: null
        };
    },
    computed: {
        ...mapGetters({
            accessToken: "getAccessToken",
            message: "getMessage",
        })
    },
    mounted() {
        if (this.accessToken !== null) {
            Siren.Client.addHeader("Authorization", `Bearer ${this.accessToken}`);
            return Siren.get(process.env.VUE_APP_API_URL + "/api/").then(res => {
                this.$router.push({ path: "/" });
            });
        }
    },

    methods: {
        onSubmit() {
            this.$validator.validate().then(valid => {
                if (valid) {
                    this.loading = true;
                    this.errors.clear();
                    this.error = null;
                    axios.post(process.env.VUE_APP_API_URL + '/api/user/reset-password/' +this.$route.params.token +'/' + this.$route.params.id, {
                        password: this.password,
                        confirm_password: this.confirmPassword
                    })
                        .then(response => {
                            this.$store.dispatch(
                                "setMessage",
                                response.data.success
                            );
                            this.loading = false;
                            if(this.$route.query.redirect_to){
                                this.$router.push("/login?redirect_to=" + this.$route.query.redirect_to);
                            }else {
                                this.$router.push("/login");
                            }
                            return response;
                        })
                        .catch(error => {

                            this.loading = false;
                            if (error.response.status !== 422) {
                                this.errors = error?.response?.data?.errors;
                                if(!this.error){
                                    this.error = error?.response?.data?.message
                                }
                                if(!this.error){
                                    this.error = error;
                                }
                            }


                            this.$setLaravelValidationErrorsFromResponse(error.response.data);
                        });
                }
            });
        },
        togglePassword() {
            this.showPassword = !this.showPassword
        },
        toggleConfirmPassword() {
            this.showConfirmPassword = !this.showConfirmPassword
        },
        goToLogin(){
            if(this.$route.query.redirect_to){
                this.$router.push("/login?redirect_to=" + this.$route.query.redirect_to);
            }else {
                this.$router.push("/login");
            }
        },
        messages(response) {
            return response.data.entities
                .filter(function (entity) {
                    return entity.rel.includes("status-messages");
                })[0];
        }
    }

}
</script>

<style scoped>

</style>
